@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Nunito:wght@300;400&display=swap");

body {
  margin: 0;
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Cinzel", serif !important;
}
